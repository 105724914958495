import { withRouter } from 'react-router';
import React, { useEffect } from 'react';

import useScript from 'services/hook/useScript';

const Layout = ({ children }) => {
  useScript('../scripts/appsflyer_event.js');
  useScript('../scripts/em_event.js');

  /*const handleFlutterWebView = (event) => {
    window.AndroidEM.firebaseEvent = function (value) {
      window.flutter_inappwebview.callHandler('AndroidEM', 'firebaseEvent', value);
    };
    window.AndroidEM.customEvent = function (value) {
      window.flutter_inappwebview.callHandler('AndroidEM', 'customEvent', value);
    };
    window.AndroidEM.getDeviceInfo = function () {
      return new Promise(function (resolve, reject) {
        window.flutter_inappwebview.callHandler('AndroidEM', 'getDeviceInfo').then(function (data) {
          //alert(JSON.stringify(data));
          resolve(data);
        });
      });
    };

    console.log('flutterInAppWebViewPlatformReady');
  };

  useEffect(() => {
    window.addEventListener('flutterInAppWebViewPlatformReady', handleFlutterWebView);

    return () => {
      window.removeEventListener('flutterInAppWebViewPlatformReady', handleFlutterWebView);
    };
  }, []);*/
  return (
    <>
      <div className="wrapper">{children}</div>
    </>
  );
};

export default withRouter(Layout);
