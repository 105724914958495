import errorReducer from '../common/reducers/errorReducer';
import userReducer from '../common/reducers/userReducer';
import messageReducer from '../common/reducers/messageReducer';
import ipAddressReducer from '../common/reducers/ipAddressReducer';

/**
 * Function is super basic analog to Redux's combineReducers().
 * @param {Object} state Current application state.
 * @param {Action} action Dispatched action.
 * @returns New application state.
 */

export default function appReducer(state, action) {
    const { error, user, message, ipAddress } = state;
    const updatedState = {
        error: errorReducer(error, action),
        user: userReducer(user, action),
        ipAddress: ipAddressReducer(ipAddress, action),
        message: messageReducer(message, action),
    };
    return updatedState;
}
