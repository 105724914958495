import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay, runNow = false) {
    const savedCallback = useRef();

    let handleId;
    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const tick = () => {
        savedCallback.current();
        schedule();
    }

    const schedule = () => {
        clearTimeout(handleId);
        handleId = setTimeout(tick, delay);
    }

    // Set up the interval.
    useEffect(() => {
        if (!delay && delay !== 0) {
            return
        }

        if (runNow) {
            tick();
        } else {
            schedule();
        }
        return () => clearTimeout(handleId);
    }, [runNow]);
}