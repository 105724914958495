import React, { useEffect, useState } from 'react';
import { API } from 'common/constants/api';

const ScrollToTopBtn = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>
            {scrollPosition >= 200 && (
                <div className='scroll-top-btn' onClick={handleToTop}>
                    <img src={API.CDN_REACT + '/desktop/up-arrow.png'} />
                </div>
            )}

        </>
    )
}

export default ScrollToTopBtn;