import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import React from 'react';

//store
import { useStore } from 'store/StateProvider';

//common
import { ROUTES } from 'common/constants/routing';
import { isUserLoggedIn } from 'common/helpers/localStorage';

//components
import Layout from '../layouts/Layout';
import ScrollToTop from "../../common/scrollToTop/scrollToTop";
import { BrandRoutes } from './BrandRoutes';
const { Footer, Topbar, Support } = BrandRoutes;
/**
 * @param {Component} component route component
 * @param {Boolean} privateRoute only allow login user if privateRoute = true
 * @param {Boolean} protectedRoute not allow login user if protectedRoute = true
 * @param {Boolean} showFooter show or hide footer; default = true
 */
const LayoutRoute = ({ component: Component, privateRoute, protectedRoute, showFooter = true, ...rest }) => {
  const [store] = useStore();
  const currentPath = window.location.pathname;
  const loggedIn = isUserLoggedIn();

  //here code is for third party login, need user to set loginname, first/last name
  if (loggedIn && store.user) {
    const whitelisted = [ROUTES.ACCOUNT.THIRD_PARTY_UPDATE_INFO, ROUTES.ACCOUNT.LOGOUT];
    if (!store.user.loginName && !whitelisted.includes(currentPath)) {
      return <Redirect to={ROUTES.ACCOUNT.THIRD_PARTY_UPDATE_INFO} />;
    } else if (store.user.loginName && currentPath === ROUTES.ACCOUNT.THIRD_PARTY_UPDATE_INFO) {
      return <Redirect to={ROUTES.HOME} />;
    }
  }

  const routeComponent = (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <ScrollToTop />
          <Topbar />
          <Support />
          <Layout>
            <Component {...matchProps} />
          </Layout>
          {showFooter && <Footer />}
        </>
      )}
    />
  );

  const component = () => {
    if (privateRoute && !loggedIn) {
      return <Redirect to={ROUTES.ACCOUNT.LOGIN} />;
    } else if (protectedRoute && loggedIn) {
      return <Redirect to={ROUTES.HOME} />;
    }
    return routeComponent;
  };

  return component();
};

LayoutRoute.defaultProps = {
  privateRoute: false,
  protectedRoute: false,
};

LayoutRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  privateRoute: PropTypes.bool,
  protectedRoute: PropTypes.bool,
};

export default LayoutRoute;
