import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';

//components
import LaunchGameHandler from '../common/LaunchGame/LaunchGame';
import Loading from 'component/mobile/common/Loading/Loading';

//service
import { useSearchByName } from 'services/gameService';

export default function SearchByName() {
  const [name, setName] = useState(null);
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);
  const [{ data, loading }, executeSearch] = useSearchByName(name);

  useEffect(() => {
    if (data) {
      const filterData = data.subGameList.map((value) => {
        return { name: value.name, id: value.id, gameId: value.gameId, icon: value.icon };
      });
      setOptions(filterData);
    }
  }, [data]);

  useEffect(() => {
    if (name) {
      const delayDebounceFn = setTimeout(() => {
        executeSearch();
      }, 900);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [name, executeSearch]);

  return (
    <>
      {loading && <Loading loading={loading} />}

      {selected && (
        <LaunchGameHandler
          subgame={selected}
          fireEvent={true}
          key={selected.name}
        ></LaunchGameHandler>
      )}
      <Autocomplete
        required
        onInputChange={(event, value, reason) => {
          if (reason === 'input') {
            setName(value);
            setOptions([]);
            setSelected(null);
          }
        }}
        onChange={(event, newValue) => {
          setName(null);
          setSelected(newValue);
        }}
        noOptionsText={'Enter Game Name'}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {/* {params.InputProps.endAdornment} */}
                  <SearchIcon />
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {/* <input type="text" placeholder="Search only game name..." />
      <div className="search"></div> */}
    </>
  );
}
