export const ROUTES = {
    HOST: window.APP_HOST || "http://localhost:3000",
    ERROR: '/error',
    HOME: '/',
    ACCOUNT: {
        ROOT: '/account',
        REGISTER: '/account/register',
        LOGIN: '/account/login',
        VERIFY: '/account/verify-email',
        FORGOT_PASSWORD: '/account/forgot-password',
        RESET_PASSWORD: '/account/reset-password',
        LOGOUT: '/account/logout',
        THIRD_PARTY_UPDATE_INFO: '/account/update-primary-info',
        THIRD_PARTY_INSTRAGRAM_CALLBACK: '/account/instagram/callback',
    },
    MESSAGE: {
        ROOT: '/message',
        READ: '/message/:categoryId',
    },
    USER: {
        PROFILE: {
            ROOT: '/profile',
            EDIT: '/profile/edit',
        },
    },
    WALLET: {
        DEPOSIT: '/wallet/deposit',
        WITHDRAWAL: '/wallet/withdrawal',
        WITHDRAWALBINDING: '/wallet/withdrawalbinding',
        WITHDRAWALRESULT: '/wallet/withdrawalresult',
        WRAPPER: '/wallet/processing'
    },
    TRANSACTION: {
        ROOT: '/transaction',
    },
    DASHBOARD: {
        ROOT: '/userdashboard',
    },
    DOWNLOAD: {
        ROOT: '/download',
    },
    PROMOTION: {
        ROOT: '/promotion',
        READ: '/promotion/:id',
    },
    MAINTENANCE: {
        ROOT: '/maintenance',
    },
    DTCasino: {
        ROOT: '/casino',
        WM: '/casino#wm',
        XG: '/casino#xg',
    },
    SLOT: {
        ROOT: '/#slot',
        HOME: '/slots',
    },
    CONTACT: {
        ROOT: '/contact',
    },
    POLICY: {
        ROOT: '/policy',
    },
    RESPONSIBLEGAMING: {
        ROOT: '/responsiblegaming'
    },

    //tmp route
    SPORTS: {
        ROOT: '/sports',
    },
    TABLE: {
        ROOT: '/table',
    },
    POKER: {
        ROOT: '/poker',
    },
    ESPORTS: {
        ROOT: '/esports',
    },
    ANIMAL: {
        ROOT: '/animal',
    },
    FISHING: {
        ROOT: '/fishing',
    },
};