import { useEffect } from 'react';
import axios from 'axios';
import useAxios from 'axios-hooks';

//common
import {
    deleteAccessToken,
    setAccessToken,
    getAccessToken,
    deleteExpiredTime,
} from '../common/helpers/localStorage';
import { METHODS } from '../common/constants/keys';
import { API } from '../common/constants/api';
import { setUserDetails, userLogout } from '../common/reducer-actions/userActions';
import { switchTheme } from '../common/helpers/helper';

//store
import { useDispatch } from '../store/StateProvider';

const { HOST, APIBASE, ACCOUNT, AFFILIATE } = API;

//for refresh token used
export function useUserPing() {
    const [{ data, loading, error }, ping] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.PING}`,
        method: METHODS.GET,
    }, { manual: true }, );
    return [{ data, loading, error }, ping];
}

export function useUserSessionActive(userId) {
    const [{ data, loading, error }, refetch] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.SESSION_ACTIVE}/${userId}`,
        method: METHODS.GET,
    }, { manual: true }, );

    return [{ data, loading, error }, refetch];
}

export function useUserLogin() {
    const dispatch = useDispatch();
    const [{ data, loading, error }, register] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.LOGIN}`,
        method: METHODS.POST,
    }, { manual: true }, );

    useEffect(() => {
        if (data) {
            switchTheme(data?.theme);
            setAccessToken(data.jwtToken);
            setUserDetails(dispatch, data);
        }
    }, [data, dispatch]);

    return [{ data, loading, error }, register];
}

export function useThirdPartyLogin() {
    const dispatch = useDispatch();
    const [{ data, loading, error }, register] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.THIRD_PARTY_LOGIN}`,
        method: METHODS.POST,
    }, { manual: true }, );

    useEffect(() => {
        if (data) {
            setAccessToken(data.jwtToken);
            setUserDetails(dispatch, data);
        }
    }, [data, dispatch]);

    return [{ data, loading, error }, register];
}

export function useThirdPartyUpdate() {
    const dispatch = useDispatch();
    const [{ data, loading, error }, update] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.THIRD_PARTY_UPDATE}`,
        method: METHODS.POST,
    }, { manual: true }, );

    useEffect(() => {
        if (data) {
            setUserDetails(dispatch, data);
        }
    }, [data, dispatch]);

    return [{ data, loading, error }, update];
}

export function useUserLogout() {
    const dispatch = useDispatch();
    const [{ data, loading, error }] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.REVOKE_TOKEN}`,
        method: METHODS.POST,
        data: {},
    });

    useEffect(() => {
        if (data || error) {
            deleteAccessToken();
            deleteExpiredTime();
            userLogout(dispatch);
        }
    }, [dispatch, data, error]);

    return { data, loading, error };
}

export function useGetCurrentUser(isManual = false) {
    const dispatch = useDispatch();
    const [{ data, loading, error }, refetch] = useAxios(`${HOST}${APIBASE}${ACCOUNT.CURRENT_USER}`, {
        manual: isManual,
    });

    useEffect(() => {
        if (data) {
            switchTheme(data?.theme);
            setUserDetails(dispatch, data);
        }
    }, [data, dispatch]);

    return [{ data, loading, error }, refetch];
}

export const refreshTokenInterceptor = async() => {
    const oldAccessToken = getAccessToken();
    if (oldAccessToken) {
        const refreshResult = await axios
            .post(`${HOST}${APIBASE}${ACCOUNT.REFRESH_TOKEN}`, {})
            .catch((err) => {
                //Prevent multiple refreshtoken request, if first request success will update access token,
                //so other request access token will differernt with new access token.
                if (oldAccessToken === getAccessToken()) {
                    deleteAccessToken();
                }
                return false;
            });
        setAccessToken(refreshResult.data.jwtToken);
    }
    return true;
};

export function useUserRegister() {
    const [{ data, loading, error }, register] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.REGISTER}`,
        method: METHODS.POST,
    }, { manual: true }, );

    return [{ data, loading, error }, register];
}

export function useGetReferralName(referralCode) {
    const [{ data, loading, error }, execute] = useAxios({
        url: `${HOST}${APIBASE}${AFFILIATE.GET_NAME.replace(':code', referralCode)}`,
        method: METHODS.GET,
    }, { manual: true });

    const rData = data;
    const rLoading = loading;
    const rError = error;

    return [{ rData, rLoading, rError }, execute];
}

export function useGetReferralNameByDomain(domain) {
    const [{ data, loading, error }, executeDomain] = useAxios({
        url: `${HOST}${APIBASE}${AFFILIATE.GET_NAME_DOMAIN.replace(':code', domain)}`,
        method: METHODS.GET,
    }, { manual: true });

    return [{ data, loading, error }, executeDomain];
}

export function useForgotPassword() {
    const [{ data, loading, error }, register] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.FORGOT_PASSWORD}`,
        method: METHODS.POST,
    }, { manual: true }, );

    return [{ data, loading, error }, register];
}

export function useResetPassword() {
    const [{ data, loading, error }, register] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.RESET_PASSWORD}`,
        method: METHODS.POST,
    }, { manual: true }, );

    return [{ data, loading, error }, register];
}

export function useUpdateAccount() {
    const dispatch = useDispatch();
    const [{ data, loading, error }, execute] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.UPDATE}`,
        method: METHODS.POST,
    }, { manual: true }, );

    useEffect(() => {
        if (data) {
            setUserDetails(dispatch, data);
        }
    }, [data, dispatch]);

    return [{ data, loading, error }, execute];
}

export function useGetMemberSummary() {
    const [{ data, loading, error }, refetch] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.SUMMARY}`,
        method: METHODS.GET,
    });

    return [{ data, loading, error }, refetch];
}

export function useVerifyAccount() {
    const [{ data, loading, error }, register] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.VERIFY}`,
        method: METHODS.POST,
    }, { manual: true }, );

    return [{ data, loading, error }, register];
}

export function useRequestVerifyAccount() {
    const dispatch = useDispatch();

    const [{ data, loading, error }, execute] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.REQUEST_VERIFY}`,
        method: METHODS.POST,
    }, { manual: true }, );

    useEffect(() => {
        if (error && error.indexOf('been verified') > -1) {
            setUserDetails(dispatch, { isVerified: true });
        }
    }, [error, dispatch]);

    return [{ data, loading, error }, execute];
}

export function useSendVerifySms() {
    const [{ data, loading, error }, execute] = useAxios({
        url: `${HOST}${APIBASE}${ACCOUNT.SENDSMS}`,
        method: METHODS.POST,
    }, { manual: true }, );

    return [{ data, loading, error }, execute];
}