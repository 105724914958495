const LP99_cn = {
    "login": {
        "title": "登入",
        "form_login_label": "电邮 / 用户名",
        "form_pw_label": "密码",
        "form_remember_label": "记得我",
        "form_forget_pw_label": "忘记密码",
        "not_reg_label": "尚未注册?",
        "reg_btn": "注册",
        "info": "* 如果没有收到验证电邮, 请查看优惠或者全部分组。"
    },
}

export default LP99_cn;