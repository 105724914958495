import React, { lazy, Suspense } from 'react';

let FooterCom, TopbarCom, SideBarCom, HomeCom, UpdatePrimaryInfoCom, LoginCom, LogoutCom, RegisterCom, ForgotPasswordCom, ResetPasswordCom, ProfileCom,
    ErrorPageCom, NotFoundCom, DepositCom, ProfileEditCom, VerifyCom, MessagesCom, MessageCom, TransactionCom, WithdrawalCom, DownloadCom,
    PromotionCom, PromotionDetailsCom, WithdrawalBindingCom, InstagramCallbackCom, MaintenanceCom, WithdrawResultCom;

//default components for default project LP99 
FooterCom = lazy(() => import("component/mobile/app/layouts/Footer/Footer"));
TopbarCom = lazy(() => import("component/mobile/app/layouts/Topbar/Topbar"));
SideBarCom = lazy(() => import("component/mobile/app/layouts/SideBar/SideBar"));
HomeCom = lazy(() => import("component/mobile/home/Home"));
UpdatePrimaryInfoCom = lazy(() => import("component/mobile/account/UpdatePrimaryInfo/UpdatePrimaryInfo"));
LoginCom = lazy(() => import("component/mobile/account/Login/Login"));
LogoutCom = lazy(() => import("component/mobile/account/Logout/Logout"));
RegisterCom = lazy(() => import("component/mobile/account/Register/Register"));
ForgotPasswordCom = lazy(() => import("component/mobile/account/ForgotPassword/ForgotPassword"));
ResetPasswordCom = lazy(() => import("component/mobile/account/ResetPassword/ResetPassword"));
ProfileCom = lazy(() => import("component/mobile/user/Profile/Profile"));
ErrorPageCom = lazy(() => import("component/mobile/error-page/ErrorPage/ErrorPage"));
NotFoundCom = lazy(() => import("component/mobile/error-page/NotFound/NotFound"));
DepositCom = lazy(() => import("component/mobile/wallet/Deposit/Deposit"));
ProfileEditCom = lazy(() => import("component/mobile/user/Edit/ProfileEdit"));
VerifyCom = lazy(() => import("component/mobile/account/Verify/Verify"));
MessagesCom = lazy(() => import("component/mobile/message/MessageList/Messages"));
MessageCom = lazy(() => import("component/mobile/message/Message/Message"));
TransactionCom = lazy(() => import("component/mobile/transaction/Transaction"));
WithdrawalCom = lazy(() => import("component/mobile/wallet/Withdrawal/Withdrawal"));
DownloadCom = lazy(() => import("component/mobile/download/Download"));
PromotionCom = lazy(() => import("component/mobile/promotion/Promotion"));
PromotionDetailsCom = lazy(() => import("component/mobile/promotion/PromotionDetails"));
WithdrawalBindingCom = lazy(() => import("component/mobile/wallet/WithdrawalBinding/WithdrawalBinding"));
InstagramCallbackCom = lazy(() => import("component/mobile/account/Login/ThirdParty/InstagramCallback"));
MaintenanceCom = lazy(() => import("component/mobile/maintenance/Maintenance"));
WithdrawResultCom = lazy(() => import("component/mobile/wallet/Withdrawal/ResultPage/WithdrawResult"));


//switch to different component by brands

switch (window.WEB_CODE) {
    case 'SW99':
        HomeCom = lazy(() => import("component/mobile/homeINR/Home"));
        RegisterCom = lazy(() => import("component/mobile/account/Register/RegisterSW99"));
        FooterCom = lazy(() => import("component/mobile/app/layouts/Footer/FooterSW99"));
        DownloadCom = lazy(() => import("component/mobile/download/DownloadINR"));
        break;
    // default:
    //   FooterCom = lazy(() => import("component/mobile/app/layouts/Footer/Footer"));
    //   TopbarCom = lazy(() => import("component/mobile/app/layouts/Topbar/Topbar"));

}

//component wrap with Suspense
const withSuspense = Component => ({ ...props }) => (
    <>
        <Suspense fallback={<div></div>}>
            <Component {...props} />
        </Suspense>
    </>
);

export const BrandRoutes = {
    Footer: withSuspense(FooterCom),
    Topbar: withSuspense(TopbarCom),
    SideBar: withSuspense(SideBarCom),
    Home: withSuspense(HomeCom),
    UpdatePrimaryInfo: withSuspense(UpdatePrimaryInfoCom),
    Login: withSuspense(LoginCom),
    Logout: withSuspense(LogoutCom),
    Register: withSuspense(RegisterCom),
    ForgotPassword: withSuspense(ForgotPasswordCom),
    ResetPassword: withSuspense(ResetPasswordCom),
    Profile: withSuspense(ProfileCom),
    ErrorPage: withSuspense(ErrorPageCom),
    NotFound: withSuspense(NotFoundCom),
    Deposit: withSuspense(DepositCom),
    ProfileEdit: withSuspense(ProfileEditCom),
    Verify: withSuspense(VerifyCom),
    Messages: withSuspense(MessagesCom),
    Message: withSuspense(MessageCom),
    Transaction: withSuspense(TransactionCom),
    Withdrawal: withSuspense(WithdrawalCom),
    Download: withSuspense(DownloadCom),
    Promotion: withSuspense(PromotionCom),
    PromotionDetails: withSuspense(PromotionDetailsCom),
    WithdrawalBinding: withSuspense(WithdrawalBindingCom),
    InstagramCallback: withSuspense(InstagramCallbackCom),
    Maintenance: withSuspense(MaintenanceCom),
    WithdrawResult: withSuspense(WithdrawResultCom),
}