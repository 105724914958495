import {isMobile} from 'react-device-detect';
import _ from 'lodash';
import axios from 'axios';
import { STORAGE } from '../constants/keys';
import { getLocalStorage, setLocalStorage } from './localStorage';

export function isToday(date) {
  const compareDate = new Date(date);
  const today = new Date();
  return (
    compareDate.getDate() === today.getDate() &&
    compareDate.getMonth() === today.getMonth() &&
    compareDate.getFullYear() === today.getFullYear()
  );
}

export function isEmptyString(value) {
  return !value || value === '';
}

export function isString(value) {
  return _.isString(value) && !_.isEmpty(value);
}

export function isArray(value) {
  return _.isArray(value) && !_.isEmpty(value);
}

export function isNumber(value) {
  return _.isNumber(value) && _.isFinite(value) && !_.isNaN(value);
}

export function isDate(value) {
  return _.isDate(value);
}

export function isEmail(value) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

export const getParamFromUrl = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const objectToUrlParam = (obj) => {
  const params = Object.entries(obj)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
};

//https://stackoverflow.com/questions/4460586/javascript-regular-expression-to-check-for-ip-addresses
//https://stackoverflow.com/questions/23483855/javascript-regex-to-validate-ipv4-and-ipv6-address-no-hostnames
export const ValidateIPaddress = (ipaddress) => {
  let ipv4_valid =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress,
    );
  let ipv6_valid =
    /^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm.test(
      ipaddress,
    );
  if (ipv4_valid || ipv6_valid) {
    return true;
  }

  return false;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

//https://getjsonip.com/#plus
//https://www.cloudflare.com/cdn-cgi/trace
//https://1.1.1.1/cdn-cgi/trace
export const getClientIpByList = async () => {
  let retry = false;
  let index = 0;
  let list = [
    'https://www.cloudflare.com/cdn-cgi/trace',
    'https://api.ipify.org/?format=json',
    'https://jsonip.com/',
    'http://www.geoplugin.net/json.gp',
    'https://geolocation-db.com/json/',
    // 'http://no-tls.jsonip.com/',
  ];
  // shuffleArray(list);

  let response = [undefined, undefined];
  do {
    let requestUrl = list[index];
    retry = false;
    const instance = axios.create({
      withCredentials: false,
    });
    response = await instance
      .get(requestUrl, { withCredentials: false, timeout: 2000 })
      .then((data) => {
        switch (requestUrl) {
          case 'https://www.cloudflare.com/cdn-cgi/trace':
            let arr = data.data
              .trim()
              .split('\n')
              .map((e) => e.split('='));
            let obj = Object.fromEntries(arr);
            return [obj.ip, undefined];
          case 'https://api.ipify.org/?format=json':
            return [data?.data?.ip || undefined, undefined];
          case 'https://geolocation-db.com/json/':
            return [data?.data?.IPv4 || undefined, undefined];
          case 'http://www.geoplugin.net/json.gp':
            return [data?.data?.geoplugin_request || undefined, undefined];
          case 'http://no-tls.jsonip.com/':
            return [data?.data?.ip || undefined, undefined];
          case 'https://jsonip.com/':
            return [data?.data?.ip || undefined, undefined];
          default:
            return [undefined, 'unknow api'];
        }
      })
      .catch((error) => {
        return Promise.resolve([undefined, error]);
      });

    // console.log(response);
    // console.log(ValidateIPaddress('2a01:7e00::f03c:93ff:fe33:72cd'));
    // console.log(ValidateIPaddress('2606:4700:4700::64'));
    // console.log(ValidateIPaddress('::1111:2222:3333:4444:5555:6666::'));
    // console.log(ValidateIPaddress('1:2:3::4:5::7:8'));
    if (response[0] === undefined || response[1] !== undefined || !ValidateIPaddress(response[0])) {
      retry = true;
    }

    if (index >= list.length - 1) {
      retry = false;
      response = [null, 'Ip Address error'];
    } else {
      index = index + 1;
    }
  } while (retry);

  return response;
};

export const getClientIp = async () => {
  return await axios.get('https://api.ipify.org/?format=json', { withCredentials: false });
};

export const handlePromise = (promise) => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => Promise.resolve([undefined, error]));
};

export const sortListAsc = (list) => {
  return list.sort((a, b) => (b.order != null) - (a.order != null) || a.order - b.order);
};

export const isValidTheme = (theme) => {
  const themeList = ['default', 'vip'];
  let isValidTheme = themeList.includes(theme);
  return isValidTheme;
};

export const loadTheme = (isMobile) => {
  let theme = 'default';
  let platform ='desktop';
  let storageTheme = getLocalStorage(STORAGE.Theme);
  if (!isValidTheme(storageTheme)) {
    storageTheme = 'default'; 
  } 

  if (storageTheme !== undefined) {
    theme = storageTheme;
  }

  removeCss(theme);

  if(isMobile){
    platform = 'mobile';
  }

  let themePath = '/css/' + platform + '/'+ window.WEB_CODE?.toLowerCase()+'/' + theme + '.css';
  setLocalStorage(STORAGE.Theme, theme);
  loadCss(themePath, theme);
};

export const switchTheme = (theme) => {
  if (theme === undefined || theme === '' || !isValidTheme(theme)) {
    theme = 'default';
  }

  let platform ='desktop';
  if(isMobile){
    platform = 'mobile';
  }

  let themePath = '/css/' + platform + '/'+ window.WEB_CODE.toLowerCase() +'/'+ theme + '.css';
  let storageTheme = getLocalStorage(STORAGE.Theme);
  
  if (storageTheme === undefined) {
    loadCss(themePath, theme);
  } else if (storageTheme !== undefined && storageTheme !== theme) {
    removeCss(storageTheme);
    loadCss(themePath, theme);
  }
  setLocalStorage(STORAGE.Theme, theme);
};

export const loadCss = (url, id) => {
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  link.id = id;
  document.body.appendChild(link);
};

export const removeCss = (id) => {
  var el = document.getElementById(id);
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
};
