import axios from 'axios';
import { configure } from 'axios-hooks';
// import { v4 as uuidv4 } from 'uuid';

//common
import { STORAGE } from '../../common/constants/keys';
import {
  getAccessToken,
  deleteAccessToken,
  deleteExpiredTime,
} from '../../common/helpers/localStorage';

//service
import { refreshTokenInterceptor } from '../accountService';
import { ROUTES } from '../../common/constants/routing';

//store
import { userLogout } from '../../common/reducer-actions/userActions';

//This is a copy from axiosInterceptors
export const setupInterceptors = (history, dispatch) => {
  configure({ cache: false });
  axios.defaults.withCredentials = true;
  // request interceptor to add token to request headers
  axios.interceptors.request.use(
    async (config) => {
      const whitelisted = ['https://api.ipify.org/?format=json'];
      const token = getAccessToken(STORAGE.AccessToken);
      config.headers = {
        ...config.headers,
        WebCode: window.WEB_CODE,
      };
      if (!whitelisted.includes(config.url) && token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  const LogoutHandle = () => {
    deleteAccessToken();
    deleteExpiredTime();
    userLogout(dispatch);
    history.push(ROUTES.ACCOUNT.LOGOUT);
    
    return Promise.reject('There is no action for too long time, system automatically log out');
  };
  const maxRefreshCount = 5;
  let isRefreshing = false;
  // response interceptor intercepting 401 responses, refreshing token and retrying the request
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      // const processId = uuidv4();
      const config = error.config;

      if (error?.response?.status === 503) {
        history.push(ROUTES.MAINTENANCE.ROOT);
      }

      if (
        error?.response?.status === 401 &&
        config.headers.Authorization &&
        getAccessToken() &&
        !config._retry
      ) {
        config._retry = true;
        if (isRefreshing) {
          let refreshCount = 1;
          while (isRefreshing && refreshCount <= maxRefreshCount) {
            await new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve();
              }, 700);
            });
            refreshCount++;
          }

          if (refreshCount > maxRefreshCount) {
            return Promise.reject('There is no action for too long time, system automatically log out');
          }

          if (getAccessToken()) {
            return axios.request(config);
          }
        }

        isRefreshing = true;
        await refreshTokenInterceptor()
          .catch((error) => {
            LogoutHandle();
          })
          .then((resp) => {
            if (!resp) {
              LogoutHandle();
            }
          });

        isRefreshing = false;
        if (getAccessToken()) {
          return axios.request(config);
        }
      } else if (
        error?.response?.status === 401 &&
        config.headers.Authorization &&
        getAccessToken() &&
        config._retry
      ) {
        LogoutHandle();
      }

      const errorMsg =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error?.message ||
        'Server Error';
      return Promise.reject(errorMsg);
    },
  );
};
