import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import IdleTimer from './IdleTimer';

//common
import { ROUTES } from 'common/constants/routing';

//component
import { useSnackbar } from 'notistack';

//warningMin = before timeout display warning
export default function IdleLogoutTimer({ warningMin, timeoutMin }) {
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const warning = useCallback((warningMin) => {
    enqueueSnackbar(
      'After ' + warningMin + ' min you will be automatically log out, please do anything to keep the session.',
      {
        variant: 'warning',
        autoHideDuration: 10000,
      },
    );
  }, [enqueueSnackbar]);

  const logout = useCallback(() => {
    /*enqueueSnackbar('There is no action for too long time, system automatically log out', {
      variant: 'success',
      autoHideDuration: 10000,
    });*/
    history.push(ROUTES.ACCOUNT.LOGOUT);
  }, [history]);

  useEffect(() => {
    if (timeoutMin) {
      const timer = new IdleTimer({
        timeoutMin: timeoutMin,
        warningMin: warningMin,
        onTimeout: () => logout(),
        onWarning: () => warning(warningMin),
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [warningMin, timeoutMin, logout, warning]);

  return <></>;
}
