import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

//service
import {
  useLaunchGame,
  useAddRecentGamePlay,
  useUpdateLimitSetting,
} from 'services/gameService';

//routes for link
import { ROUTES } from 'common/constants/routing';
import { isUserLoggedIn } from 'common/helpers/localStorage';
import { API } from 'common/constants/api';
import { Settings } from 'common/constants/common';
import { getClientIp, getClientIpByList, handlePromise } from 'common/helpers/helper';

//components
import LaunchGameDialog from 'component/mobile/common/Dialogs/Common/LaunchGameDialog';
import Loading from 'component/mobile/common/Loading/Loading';
import { SubmitButton } from 'component/mobile/common/Buttons/SubmitButton';
import { LinkButton } from 'component/mobile/common/Buttons/LinkButton';
import GameLimitSetting from './GameLimitSettings';

const DEFAULT_IMG = Settings.SLOT_DEFAULT_IMG;

//fireEvent used by search game name
export default function LaunchGameHandler({ subgame, fireEvent, children, addRecentPlay = true }) {
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [lock, setLock] = useState(false);
  const [gameUrl, setGameUrl] = useState(null);
  const [hasLimit, setHasLimit] = useState(false);
  const [limitValue, setLimitValue] = useState(null);
  const [clickable, setClickable] = useState(true);

  const [{ data, loading, error }, launchGame] = useLaunchGame();
  const [{ uError }, updateLimitSetting] = useUpdateLimitSetting();
  const [, addRecentGamePlay] = useAddRecentGamePlay();

  //update limit setting error
  useEffect(() => {
    if (uError) {
      setLimitValue(null);
      enqueueSnackbar(uError, {
        variant: 'error',
        autoHideDuration: 2500,
      });
    }
  }, [uError, setLimitValue, enqueueSnackbar]);

  const submit = useCallback(async () => {
    let [ipdata] = await handlePromise(getClientIpByList());
    const ipAddress = (ipdata && ipdata[0]) || undefined;
    launchGame({
      data: {
        gameId: subgame.gameId.toString(),
        subGameId: subgame.id,
        ipAddress: ipAddress,
      },
    });
  }, [launchGame, subgame]);

  const launchHandler = async () => {
    if (!isUserLoggedIn()) {
      return history.push(ROUTES.ACCOUNT.LOGIN);
    }

    if (!lock) {
      //has limit template game id
      if (subgame && (subgame.gameId === 1 || subgame.hasLimit === 1)) {
        setHasLimit(true);
      }
      setLock(true);
      submit();
    }
  };

  useEffect(() => {
    if (fireEvent) {
      if (!isUserLoggedIn()) {
        history.push(ROUTES.ACCOUNT.LOGIN);
        return;
      }
      submit();
    }
    if (subgame.isMaintenance) {
      setClickable(false);
    }
  }, [fireEvent, launchGame, subgame, history, submit]);

  useEffect(() => {
    if (error) {
      setLock(false);
      enqueueSnackbar(error, {
        variant: 'error',
        autoHideDuration: 2500,
      });
      return;
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      setOpen(true);
      setLock(false);
      if (data.url) {
        if (subgame.gameId !== 1) {
          addRecentGamePlayHandle();
          return window.open(data.url, "_blank")
        }
        setGameUrl(data.url);
        //if aec and first login, do not use update betlimit
        if(data.firstLogin === 1 && subgame.gameId === 11){
            setHasLimit(false);
        }
      }
    }
  }, [data]);

  const addRecentGamePlayHandle = () => {
    if (addRecentPlay) {
      addRecentGamePlay({
        data: {
          gameId: subgame.gameId.toString(),
          subGameId: subgame.id,
        },
      });
    }
    closeHandle();
  };

  const updateLimitSettingHandle = () => {
    if (hasLimit) {
      updateLimitSetting({
        data: {
          gameId: subgame.id,
          limitId: limitValue,
        },
      });
    }
  };

  const closeHandle = () => {
    setLimitValue(null);
    setGameUrl(null);
    setOpen(false);
  };

  const onImgError = (e) => {
    e.target.src = DEFAULT_IMG;
  };

  return (
    <div>
      {loading && <Loading loading={loading} />}

      {clickable && <div onClick={() => launchHandler()}>{children}</div>}
      {!clickable && <div>{children}</div>}

      {open && gameUrl && subgame.gameId === 1 && (
        <LaunchGameDialog open={open} title={subgame.name} closeHandle={closeHandle}>
          <div className="big-icon">
            <img
              onError={(e) => onImgError(e)}
              src={subgame.icon ? API.CDN + subgame.icon : DEFAULT_IMG}
              alt={'Enter Game'}
            ></img>
          </div>

          {hasLimit && (
            <GameLimitSetting
              subgame={subgame}
              limitValue={limitValue}
              setLimitValue={setLimitValue}
            />
          )}

          {hasLimit && (!limitValue || limitValue <= 0) ? (
            <SubmitButton value="Select bet limit required." />
          ) : (
            <LinkButton
              to={{ pathname: gameUrl }}
              newTab={true}
              onClickEvent={addRecentGamePlayHandle}
            >
              <SubmitButton value="ENTER" onClick={updateLimitSettingHandle} />
            </LinkButton>
          )}
        </LaunchGameDialog>
      )}
    </div>
  );
}
