import React, { useEffect, useState } from 'react';
import { API } from 'common/constants/api';
import { Link as ScrollLink } from 'react-scroll';
import AnimatedNumber from 'animated-number-react';

//service
import useInterval from 'services/hook/useInterval';
import { useGetJackpotAmount } from 'services/gameService';

//jackpot number config
const PERCENTAGE = 0.995;
const JACKPOT_DURATION = 60000;

export default function JackpotBanner({ onClick }) {
  //if put api call inside Carousell the fn will run twice, so dont put this in Jackpot
  const [{ jData }, execute] = useGetJackpotAmount();

  //jili
  const [apiCallCount, setApiCallCount] = useState(0);
  const [data1, setData1] = useState({
    amt: 0,
    duration: 10,
  });

  const [jiliAmt, setJiliAmt] = useState(0);

  const formatValue = (value) => {
    var formatDigit = value.toFixed(2);
    var parts = formatDigit.toString().split('.');
    var returnFormatValue =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '');
    return returnFormatValue;
  };

  const onComplete1 = () => {
    //first time need add back reduced amount
    if (apiCallCount === 1 && data1.amt < jiliAmt) {
      setData1({
        ...data1,
        amt: data1.amt / PERCENTAGE,
        duration: JACKPOT_DURATION,
      });
    }
  };

  useInterval(
    () => {
      // get jackpot amount api every 40 seconds
      execute();
    },
    40000,
    true,
  );

  useEffect(() => {
    if (apiCallCount > 1) {
      setData1({
        amt: data1.amt,
        duration: JACKPOT_DURATION,
      });
    }
  }, [data1.amt]);

  useEffect(() => {
    if (jData) {
      if (jData.length > 0) {
        var jiliObj = jData.filter(function (el) {
          return el.gameId === 4; // JILI
        });

        if (jiliObj.length > 0) {
          var amtData = jiliObj[0].data;
          var amt1 = amtData[0].amount;

        }
      } else {
        var amt1 = 33585.35;
      }

      setJiliAmt(amt1);

      if (apiCallCount === 0) {
        setData1({
          amt: amt1 * PERCENTAGE,
          duration: 10,
        });
      } else {
        setData1({
          amt: amt1,
          duration: JACKPOT_DURATION,
        });
      }


      setApiCallCount(apiCallCount + 1);
    }
  }, [jData]);

  return (
    <div className="dt-mid-banner">
      <ScrollLink to="scroll-slot-all" smooth={true}>
        <div className="dt-mid-banner-left" onClick={(e) => onClick(e, 4, 2)}>
          <img src={API.CDN_REACT + '/banner-jackpot-new.png'} />
          <div className="dt-mid-banner-left-number">
            {data1.amt > 0 && (
              <AnimatedNumber
                value={data1.amt}
                complete={onComplete1}
                formatValue={formatValue}
                duration={data1.duration}
              />
            )}
          </div>
        </div>
      </ScrollLink>

      {window.WEB_CODE != 'SW99' && (
        <ScrollLink to="scroll-slot-all" smooth={true}>
          <div className="dt-mid-banner-right" onClick={(e) => onClick(e, 2, 2)}>
            <img src={API.CDN_REACT + '/desktop/banner-i8-v2.jpg'} />
          </div>
        </ScrollLink>
      )}
    </div>
  );
}
