import React, { useEffect, useState } from 'react';

//common
import { useDispatch } from 'store/StateProvider';
import { handlePromise, getClientIpByList } from 'common/helpers/helper';
import { setIpAddress } from 'common/reducer-actions/ipAddressActions';
import { ROUTES } from 'common/constants/routing';

//component
import Loading from '../common/Loading/Loading';

export default function IpAddressProvider({ children }) {
  const currentPath = window.location.pathname;
  const loadingDefault = currentPath === ROUTES.ACCOUNT.LOGIN;
  const [loading, setLoading] = useState(loadingDefault);
  const dispatch = useDispatch();

  useEffect(() => {
    async function GetClientIp() {
      let [ipdata] = await handlePromise(getClientIpByList());
      const ipAddress = (ipdata && ipdata[0]) || undefined;
      setIpAddress(dispatch, { ipAddress: ipAddress });
      setLoading(false);
    }
    GetClientIp();

    setTimeout(() => {
      setLoading(false);
    }, [1000]);
  }, [dispatch]);

  return (
    <>
      {loading && <Loading loading={loading} />}
      {children}
    </>
  );
}
