import useAxios from 'axios-hooks';

//common
import { METHODS } from '../common/constants/keys';
import { API } from '../common/constants/api';
import { objectToUrlParam } from '../common/helpers/helper';

const { HOST, APIBASE, GAME } = API;

export function useLaunchGame() {
    const [{ data, loading, error }, launchGame] = useAxios({
        url: `${HOST}${APIBASE}${GAME.LAUNCH_GAME}`,
        method: METHODS.POST,
    }, { manual: true }, );

    return [{ data, loading, error }, launchGame];
}

export function useGetLimitSettings(gameId) {
    const [{ data, loading, error }, execute] = useAxios({
        url: `${HOST}${APIBASE}${GAME.GET_LIMIT_SETTINGS}/${gameId}`,
        method: METHODS.GET,
    }, { manual: true }, );

    return [{ data, loading, error }, execute];
}

export function useUpdateLimitSetting() {
    const [{ data, loading, error }, updateLimitSetting] = useAxios({
        url: `${HOST}${APIBASE}${GAME.UPDATE_LIMIT_SETTING}`,
        method: METHODS.POST,
    }, { manual: true }, );

    const uData = data;
    const uLoading = loading;
    const uError = error;
    return [{ uData, uLoading, uError }, updateLimitSetting];
}

export function useGetGameList(isManual = false) {
    const [{ data, loading, error }, execute] = useAxios({
        url: `${HOST}${APIBASE}${GAME.GAME_LIST}`,
    }, { manual: isManual });

    return [{ data, loading, error }, execute];
}

export function useGetSubGameList(subGameType = null, isManual = false) {
    var param = '';
    if (subGameType) {
        param += `/${subGameType}`;
    }

    const [{ data, loading, error }, execute] = useAxios({
        url: `${HOST}${APIBASE}${GAME.SUBGAME}` + param,
    }, { manual: isManual });

    return [{ data, loading, error }, execute];
}

export function useAddRecentGamePlay() {
    const [{ data, loading, error }, addRecentGamePlay] = useAxios({
        url: `${HOST}${APIBASE}${GAME.ADD_RECENT_GAME_PLAY}`,
        method: METHODS.POST,
    }, { manual: true }, );

    return [{ data, loading, error }, addRecentGamePlay];
}

export function useGetRecentGamePlayed() {
    const [{ data, loading, error }] = useAxios({
        url: `${HOST}${APIBASE}${GAME.GET_RECENT_GAME_PLAY}`,
    });

    return [{ data, loading, error }];
}

export function useSearchByName(name) {
    const param = { name: name };
    const [{ data, loading, error }, executeSearch] = useAxios({
        url: `${HOST}${APIBASE}${GAME.SEARCH_BY_NAME}` + objectToUrlParam(param),
    }, { manual: true }, );

    return [{ data, loading, error }, executeSearch];
}

export function useGetJackpotAmount() {
    const [{ data, loading, error }, execute] = useAxios({
        url: `${HOST}${APIBASE}${GAME.GET_JACKPOT}`,
    }, { manual: true }, );

    const jError = error;
    const jData = data;
    return [{ jData, loading, jError }, execute];
}