export const API = {
    HOST: window.APP_API_HOST || 'http://localhost:4000',
    CDN: window.APP_API_UPLOAD_URL || 'http://localhost:5000/uploads/', //'https://bo.stg.luckyplay99.com/uploads/',
    CDN_REACT: window.APP_CDN_REACT || 'http://localhost:3000/',
    APIBASE: '/v1/api',
    ACCOUNT: {
        SESSION_ACTIVE: '/Accounts/session-active',
        PING: '/Accounts/ping',
        LOGIN: '/Accounts/authenticate',
        THIRD_PARTY_LOGIN: '/Accounts/third-party-login',
        THIRD_PARTY_UPDATE: '/Accounts/third-party-update',
        REGISTER: '/Accounts/register',
        FORGOT_PASSWORD: '/Accounts/forgot-password',
        RESET_PASSWORD: '/Accounts/reset-password',
        CURRENT_USER: '/Accounts/current',
        REVOKE_TOKEN: '/Accounts/revoke-token',
        REFRESH_TOKEN: '/Accounts/refresh-token',
        UPDATE: '/Accounts/update',
        SUMMARY: '/Accounts/summary',
        VERIFY: '/Accounts/verify-email',
        REQUEST_VERIFY: '/Accounts/request-verify-email',
        SENDSMS: '/Accounts/request-verify-sms',
    },
    WALLET: {
        BALANCE: '/Wallet/balance',
        DEPOSIT_CHANNEL: '/Wallet/deposit-channel',
        DEPOSIT_CHANNEL_SETTINGS: '/Wallet/deposit-settings',
        DEPOSIT: '/Wallet/deposit',
        WITHDRAW: '/Wallet/withdraw',
        WITHDRAW_BINDING_FILTER: '/Wallet/binding-filter',
        WITHDRAW_BINDING_ADD: '/Wallet/binding-add',
        WITHDRAW_BINDING_GET: '/Wallet/binding-get',
        WITHDRAW_BINDING_DEL: '/Wallet/binding-del',
        WITHDRAW_INFO: '/Wallet/withdraw-info',
        DEPOSIT_CHANNEL_TYPE: '/Wallet/deposit-channel-type',
    },
    BONUS: {
        VERIFY_CODE: '/Bonus/verify-code',
    },
    MESSAGE: {
        GET_MESSAGE_LIST: '/Message',
        GET_MESSAGE_LIST_BY_CATEGORY: '/Message/Channel/:id',
        GET_MESSAGE_UNREAD_COUNT: '/Message/GetMessageUnreadCount',
        DELETE_MESSAGE: '/Message/Delete',
        DELETE_MESSAGE_BY_CATEGORY: '/Message/DeleteByCategory',
    },
    GAME: {
        LAUNCH_GAME: '/Game/launch',
        SUBGAME: '/Game/subgamelist',
        ADD_RECENT_GAME_PLAY: '/Game/AddRecentGamePlayed',
        GET_RECENT_GAME_PLAY: '/Game/GetRecentGamePlayed',
        SEARCH_BY_NAME: '/Game/search',
        GET_JACKPOT: '/Game/GetJackpot',
        GET_LIMIT_SETTINGS: '/Game/GetLimitSettings',
        UPDATE_LIMIT_SETTING: '/Game/UpdateLimitSetting',
        GAME_LIST: '/Game/gamelist',
    },
    TRANSACTION: {
        GET_FILTER: '/Transaction/GetTransactionFilter',
        GET: '/Transaction/GetTransaction',
    },
    BANNER: {
        GET: '/Banner/GetBannerList/:type',
    },
    POPUPBANNER: {
        GET: '/Banner/GetPopupBanner/:type',
    },
    PROMOTION: {
        GET: '/Promotion/GetPromotionList',
        GET_DETAIL: '/Promotion/GetPromotionDetail/:id',
    },
    AFFILIATE: {
        GET_NAME: '/Affiliate/GetName/:code',
        GET_NAME_DOMAIN: '/Affiliate/GetNameByDomain/:code',
    },
    PUBLIC: {
        PING: '/pub/ping',
        MAINTENANCE: '/pub/maintenance',
    }
};