import Slots from 'component/desktop/Slots/Slots';
import React, { lazy, Suspense } from 'react';

let FooterCom, TopbarCom, HomeCom, ErrorPageCom, NotFoundCom, DTCasinoCom,
  PromotionCom, RegisterCom, LoginCom, LogoutCom, ForgotPasswordCom, ResetPasswordCom,
  VerifyCom, ContactCom, PolicyCom, ResponsibleGamingCom, SupportCom, DashBoardCom, EmptyCom,
  SlotsCom, SportsbookCom, TableCom, DownloadCom, PokerCom;

//default components for default project LP99
FooterCom = lazy(() => import("component/desktop/app/layouts/Footer/Footer"));
TopbarCom = lazy(() => import("component/desktop/app/layouts/Topbar/Topbar"));
HomeCom = lazy(() => import("component/desktop/home/Home"));
ErrorPageCom = lazy(() => import("component/desktop/error-page/ErrorPage/ErrorPage"));
NotFoundCom = lazy(() => import("component/desktop/error-page/NotFound/NotFound"));
DTCasinoCom = lazy(() => import("component/desktop/Casino/DTCasino"));
PromotionCom = lazy(() => import("component/desktop/promotion/Promotion"));
RegisterCom = lazy(() => import("component/desktop/register/Register"));
LoginCom = lazy(() => import("component/desktop/login/Login"));
LogoutCom = lazy(() => import("component/desktop/logout/logout"));
ForgotPasswordCom = lazy(() => import("component/desktop/forgot-password/ForgotPassword"));
ResetPasswordCom = lazy(() => import("component/desktop/reset-password/ResetPassword"));
VerifyCom = lazy(() => import("component/desktop/verify/Verify"));
ContactCom = lazy(() => import("component/desktop/contact/Contact"));
PolicyCom = lazy(() => import("component/desktop/policy/Policy"));
ResponsibleGamingCom = lazy(() => import("component/desktop/responsible-gaming/ResponsibleGaming"));
SupportCom = lazy(() => import("component/desktop/app/layouts/Support/Support"));
DashBoardCom = lazy(() => import("component/desktop/user/UserDashBoard"));
SlotsCom = lazy(() => import("component/desktop/empty/Empty"));
EmptyCom = lazy(() => import("component/desktop/empty/Empty"));
TableCom = lazy(() => import("component/desktop/empty/Empty"));
SportsbookCom = lazy(() => import("component/desktop/sportsbook/Sportsbook"));
DownloadCom = lazy(() => import("component/desktop/empty/Empty"));
PokerCom = lazy(() => import("component/desktop/empty/Empty"));

//switch to different component by brands

switch (window.WEB_CODE) {
  case 'SW99':
    HomeCom = lazy(() => import("component/desktop/home/HomeINR"));
    FooterCom = lazy(() => import("component/desktop/app/layouts/Footer/FooterINR"));
    TopbarCom = lazy(() => import("component/desktop/app/layouts/Topbar/TopbarSW99"));
    SlotsCom = lazy(() => import("component/desktop/Slots/Slots"));
    RegisterCom = lazy(() => import("component/desktop/register/RegisterSW99"));
    TableCom = lazy(() => import("component/desktop/Table/Table"));
    DownloadCom = lazy(() => import("component/desktop/download/DownloadINR"));
    PokerCom = lazy(() => import("component/desktop/Poker/Poker"));
    DTCasinoCom = lazy(() => import("component/desktop/Casino/DTCasinoINR"));
    SportsbookCom = lazy(() => import("component/desktop/sportsbook/SportsbookINR"));
    break;
  // default:
  //   FooterCom = lazy(() => import("component/desktop/app/layouts/Footer/Footer"));
  //   TopbarCom = lazy(() => import("component/desktop/app/layouts/Topbar/Topbar"));
  //   HomeCom = lazy(() => import("component/desktop/home/Home"));
  //   ErrorPageCom = lazy(() => import("component/desktop/error-page/ErrorPage/ErrorPage"));
  //   NotFoundCom = lazy(() => import("component/desktop/error-page/NotFound/NotFound"));
  //   DTCasinoCom = lazy(() => import("component/desktop/Casino/DTCasino"));
  //   PromotionCom = lazy(() => import("component/desktop/promotion/Promotion"));
  //   RegisterCom = lazy(() => import("component/desktop/register/Register"));
  //   LoginCom = lazy(() => import("component/desktop/login/Login"));
  //   LogoutCom = lazy(() => import("component/desktop/logout/logout"));
  //   ForgotPasswordCom = lazy(() => import("component/desktop/forgot-password/ForgotPassword"));
  //   ResetPasswordCom = lazy(() => import("component/desktop/reset-password/ResetPassword"));
  //   VerifyCom = lazy(() => import("component/desktop/verify/Verify"));
  //   ContactCom = lazy(() => import("component/desktop/contact/Contact"));
  //   PolicyCom = lazy(() => import("component/desktop/policy/Policy"));
  //   ResponsibleGamingCom = lazy(() => import("component/desktop/responsible-gaming/ResponsibleGaming"));
}


const withSuspense = Component => ({ ...props }) => (
  <>
    <Suspense fallback={<div></div>}>
      <Component {...props} />
    </Suspense>
  </>
);

export const BrandRoutes = {
  Footer: withSuspense(FooterCom),
  Topbar: withSuspense(TopbarCom),
  Home: withSuspense(HomeCom),
  ErrorPage: withSuspense(ErrorPageCom),
  NotFound: withSuspense(NotFoundCom),
  DTCasino: withSuspense(DTCasinoCom),
  Promotion: withSuspense(PromotionCom),
  Register: withSuspense(RegisterCom),
  Login: withSuspense(LoginCom),
  Logout: withSuspense(LogoutCom),
  ForgotPassword: withSuspense(ForgotPasswordCom),
  ResetPassword: withSuspense(ResetPasswordCom),
  Verify: withSuspense(VerifyCom),
  Contact: withSuspense(ContactCom),
  Policy: withSuspense(PolicyCom),
  ResponsibleGaming: withSuspense(ResponsibleGamingCom),
  Support: withSuspense(SupportCom),
  DashBoard: withSuspense(DashBoardCom),
  Slots: withSuspense(Slots),
  Empty: withSuspense(EmptyCom),
  Sportsbook: withSuspense(SportsbookCom),
  Table: withSuspense(TableCom),
  Download: withSuspense(DownloadCom),
  Poker: withSuspense(PokerCom)
}