import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AiOutlineCloseCircle } from '@react-icons/all-files/ai/AiOutlineCloseCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '4px',
    boxShadow: theme.shadows[5],
    color: theme.palette.primaryColor,
  },
}));

export default function LaunchGameDialog({ title, open, setOpen, children, closeHandle }) {
  const classes = useStyles();

  const handleClose = () => {
    closeHandle();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="launchgame-dialog-title"
      aria-describedby="launchgame-dialog-description"
    >
      <div className={classes.paper}>
        <DialogActions className="launchgame-close">
          <Button onClick={handleClose} color="primary">
            <AiOutlineCloseCircle />
          </Button>
        </DialogActions>
        <DialogTitle id="launchgame-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <div id="launchgame-dialog-description">{children}</div>
        </DialogContent>
      </div>
    </Dialog>
  );
}
