export const myTheme = {
    palette: {
        primaryColor: '#ffe4ac',
        secondaryColor: '#7E001F',
        balanceBg: '#222224',
        background: '#000',
    },
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                "&$checked": {
                    color: '#ffe4ac',
                }
            }
        },
        MuiDialog: {
            paper: {
                width: '80%',
                border: '1px solid #222224',
                background: '#000',
            }
        },
        MuiDialogTitle: {
            root: {
                textAlign: 'center',
                paddingBottom: '0',
            }
        },
        MuiTypography: {
            h6: {
                fontSize: '1rem',
                color: '#ffe4ac',
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
                marginBottom: '10px',
            }
        },
        MuiMenu: {
            paper: { //desktop select option
                backgroundColor: '#D3D3D3',
                color: '#000'
            }
        },
        MuiButton: { //desktop button
            contained: {
                backgroundColor: '#D4A66B',
                minWidth: '180px',
                padding: '4px',
                textTransform: 'none',
                "&:hover": {
                    backgroundColor: '#FFE4AC',
                }
            },
            text: {
                color: '#D4A66B',
                textTransform: 'none',
                // minWidth: '180px',
            }
        },
        MuiSelect: {
            icon: {
                color: '#fff',
            }
        }
    }

};