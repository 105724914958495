import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import { Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import { scroller } from 'react-scroll';
import { useGetSubGameList } from 'services/gameService';
import JackpotBanner from 'component/desktop/home/JackpotBanner';
import GameProvider from 'component/desktop/home/GameProvider';
import GameItem from '../common/LaunchGame/GameItem';
import ScrollToTopBtn from '../common/ScrollToTopBtn/ScrollToTopBtn';

//common
import { sortListAsc } from 'common/helpers/helper';

const Slots = () => {
    const [{ data, error }] = useGetSubGameList(2);
    const { hash } = useLocation();
    //1-Recommended, 2-New
    const [showPopularGameType, setShowPopularGameType] = useState(1);

    //999-All
    const [showProvider, setShowProvider] = useState(null);

    const [subGame, setSubGame] = useState({
        allList: [],
        newList: [],
        recommendList: [],
    });

    const handleGameFilter = (e, newValue) => {
        if (newValue) {
            setShowProvider(newValue);
        }
    };

    const onClickViewAll = (e) => {
        e.preventDefault();

        handleGameFilter(e, 999);
        scroller.scrollTo('scroll-slot-all', {
            duration: 1000,
            delay: 100,
            smooth: true,
        });
    };

    const handlePopularGameType = (e, newValue) => {
        if (newValue) {
            setShowPopularGameType(newValue);
        }
    };

    const getSubGameByCategory = useCallback((subgamelist) => {
        subgamelist = sortListAsc(subgamelist);

        const recommendList = subgamelist.filter(function (el) {
            return el.categoryIds.includes(3);
        });

        const newList = subgamelist.filter(function (el) {
            return el.categoryIds.includes(2);
        });

        setSubGame({ allList: subgamelist, recommendList, newList });
    }, []);

    useEffect(() => {
        if (subGame && subGame.allList.length > 0) {
            setShowProvider(999);
        }
    }, [subGame]);

    useEffect(() => {
        if (data && data.subGameList) {
            getSubGameByCategory(data.subGameList);
        }
    }, [data, getSubGameByCategory]);

    return (
        <>

            {/* Jackpot Carousel */}
            <div className="dt-h-jackpot">
                <div className="dt-h-jackpot-banner">
                    <JackpotBanner onClick={handleGameFilter} />
                </div>
            </div>

            <div className="dt-wrapper">
                <div className="dt-h-highlight">
                    <div className="dh-h-breadcrumbs">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Button variant="text" onClick={(e) => handlePopularGameType(e, 1)}>
                                <Typography>RECOMMENDED</Typography>
                            </Button>
                            {/* <Typography>/</Typography> */}
                            <Button variant="text" onClick={(e) => handlePopularGameType(e, 2)}>
                                <Typography>NEW</Typography>
                            </Button>
                        </Breadcrumbs>
                    </div>
                    <ul className="dt-h-slot-highlight">
                        <li>
                            <div className="dt-header">
                                Popular Games <br />
                                to <span>Play Now</span>
                            </div>
                            <div className="dt-header-desc">New games online every week</div>
                            <div className="dt-h-viewall" onClick={onClickViewAll}>
                                VIEW ALL &#62;
                            </div>
                        </li>
                        {showPopularGameType === 1 &&
                            subGame.recommendList.length > 0 &&
                            subGame.recommendList.map((item, i) => {
                                return <GameItem item={item} index={i} key={'game-items-' + i} />;
                            })}

                        {showPopularGameType === 2 &&
                            subGame.newList.length > 0 &&
                            subGame.newList.map((item, i) => {
                                return <GameItem item={item} index={i} key={'game-items-' + i} />;
                            })}
                    </ul>
                </div>
            </div>

            {/* Slots */}
            <div className="dt-wrapper">
                <div className="dt-h-slot" id="scroll-slot">
                    <h1>Best Game in the World</h1>

                    {subGame.allList.length > 0 && showProvider && (
                        <GameProvider
                            subgameList={subGame.allList}
                            handleGameFilter={handleGameFilter}
                            showProvider={showProvider}
                            hash={hash}
                        />
                    )}
                </div>
            </div>
            <ScrollToTopBtn />
        </>
    );
}

export default Slots;