import React from 'react';
import { Link } from 'react-router-dom';

export const LinkButton = ({ to, children, className, newTab, onClickEvent, ...rest }) => {
  return (
    <Link
      className={className}
      to={to}
      {...(newTab && { target: '_blank', rel: 'noreferrer' })}
      {...(onClickEvent ? { onClick: onClickEvent } : {})}
      {...rest}
    >
      {children}
    </Link>
  );
};
