import { STORAGE } from '../constants/keys';

export const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.log(err);
  }
};

export const getLocalStorage = (key) => {
  try {
    const value = localStorage.getItem(key);
    if (value === null) return undefined;
    return JSON.parse(value);
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const deleteFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.log(err);
  }
};

//#region User
export const getAccessToken = () => getLocalStorage(STORAGE.AccessToken);

export const setAccessToken = (value) => {
  setLocalStorage(STORAGE.AccessToken, value);
};

export const isUserLoggedIn = () => !!getLocalStorage(STORAGE.AccessToken);

export const deleteAccessToken = () => deleteFromLocalStorage(STORAGE.AccessToken);

export const deleteExpiredTime = () => deleteFromLocalStorage(STORAGE.ExpiredTime);
//#endregion
