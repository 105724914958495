import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import '../../../assets/css/styles.css';

//common
import { ROUTES } from 'common/constants/routing';

//store
//import { useStore } from 'store/StateProvider';

//components
import LayoutRoute from './LayoutRoute';
import { BrandRoutes } from 'component/desktop/app/routes/BrandRoutes'
import PaymentWrapper from 'component/desktop/wrapper/PaymentWrapper';
const { Home, ErrorPage, NotFound, DTCasino, Promotion, Register, Login, Logout, ForgotPassword, ResetPassword, Verify, Contact, Policy,
  ResponsibleGaming, DashBoard, Slots, Sportsbook, Table, Download, Poker, Empty } = BrandRoutes;

const DesktopRoutes = () => {
  // const [store, dispatch] = useStore();

  // useEffect(() => {
  //   console.log('store', store);
  // }, [store, dispatch]);
  const isSportPrivate=window.WEB_CODE==="SW99"?true:false;

  return (
    <Switch>
      <LayoutRoute exact path={ROUTES.HOME} component={Home} />
      <LayoutRoute path={ROUTES.ERROR} component={ErrorPage} />
      <LayoutRoute path={ROUTES.ACCOUNT.REGISTER} component={Register} protectedRoute={true} />
      <LayoutRoute path={ROUTES.ACCOUNT.LOGIN} component={Login} protectedRoute={true} />
      <LayoutRoute path={ROUTES.ACCOUNT.LOGOUT} component={Logout} privateRoute={true} />
      {/* {window.WEB_CODE === "LP99" && ( */}
      <LayoutRoute path={ROUTES.DASHBOARD.ROOT} component={DashBoard} privateRoute={true} />
      <LayoutRoute exact path={ROUTES.WALLET.DEPOSIT} component={() => <DashBoard defaultPageId={2} />} privateRoute={true} />

      {/* )} */}
      <LayoutRoute path={ROUTES.ACCOUNT.FORGOT_PASSWORD} component={ForgotPassword} protectedRoute={true} />
      <LayoutRoute path={ROUTES.ACCOUNT.RESET_PASSWORD} component={ResetPassword} protectedRoute={true} />
      <LayoutRoute path={ROUTES.ACCOUNT.VERIFY} component={Verify} />

      <LayoutRoute path={ROUTES.DTCasino.ROOT} component={DTCasino} />
      <LayoutRoute path={ROUTES.SLOT.ROOT} component={Home} />
      <LayoutRoute path={ROUTES.SLOT.HOME} component={Slots} />
      <LayoutRoute path={ROUTES.PROMOTION.ROOT} component={Promotion} />
      <LayoutRoute path={ROUTES.CONTACT.ROOT} component={Contact} />
      <LayoutRoute path={ROUTES.POLICY.ROOT} component={Policy} />
      <LayoutRoute path={ROUTES.RESPONSIBLEGAMING.ROOT} component={ResponsibleGaming} />


      <LayoutRoute path={ROUTES.SPORTS.ROOT} component={Sportsbook} privateRoute={isSportPrivate} />
      <LayoutRoute path={ROUTES.TABLE.ROOT} component={Table} />
      <LayoutRoute path={ROUTES.DOWNLOAD.ROOT} component={Download} />
      <LayoutRoute path={ROUTES.ESPORTS.ROOT} component={Empty} />
      <LayoutRoute path={ROUTES.ANIMAL.ROOT} component={Empty} />
      <LayoutRoute path={ROUTES.FISHING.ROOT} component={Empty} />
      <LayoutRoute path={ROUTES.POKER.ROOT} component={Poker} />

      <Route path={ROUTES.WALLET.WRAPPER} render={() => <PaymentWrapper />} />

      <LayoutRoute exact component={NotFound} />
    </Switch>
  );
};

export default DesktopRoutes;
