import { STORAGE } from "common/constants/keys";

class IdleTimer {
  constructor({ timeoutMin, warningMin, onTimeout, onWarning }) {
    this.events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    this.timeout = timeoutMin;
    this.warningMin = warningMin;
    this.onTimeout = onTimeout;
    this.onWarning = onWarning;
    this.hasWarning = false;

    const expiredTime = parseInt(localStorage.getItem(STORAGE.ExpiredTime), 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onTimeout();
      return;
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem(STORAGE.ExpiredTime), 10);
      const waringTime = expiredTime - this.warningMin * 60 * 1000; 
      const currentTime = Date.now();
      if (waringTime < currentTime && waringTime < expiredTime) {
        if (this.onWarning && !this.hasWarning) {
          this.onWarning();
          this.hasWarning = true;
        }
      }

      if (expiredTime > 0 && expiredTime < currentTime) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    //this.hasWarning = false;
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem(STORAGE.ExpiredTime, Date.now() + this.timeout * 60 * 1000);
    }, 300);
  }

  tracker() {
    for (let i in this.events) {
      window.addEventListener(this.events[i], this.eventHandler);
    }
  }

  cleanUp() {
    localStorage.removeItem(STORAGE.ExpiredTime);
    clearInterval(this.interval);
    for (let i in this.events) {
      window.removeEventListener(this.events[i], this.eventHandler);
    }
  }
}
export default IdleTimer;
