import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';


export default function PaymentWrapper() {
  const { search } = useLocation();
  const params = queryString.parse(search);
  const url = params.link;

  return (
    <div className="payment-wrapper">
      {url ? <iframe src={url} /> : <h4>Error, invalid payment link</h4>}
    </div>
  );
}
