import { ACTIONS } from '../constants/actions';

export default function userReducer(user, { type, payload = null }) {
    switch (type) {
        case ACTIONS.USER.USER_DETAILS_SET:
            return {
                ...user,
                ...payload,
            };
        case ACTIONS.USER.USER_BALANCE:
            return {
                ...user,
                ...payload
            };
        case ACTIONS.USER.USER_LOGOUT:
            return undefined;
        default:
            return user;
    }
}
