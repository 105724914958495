import React, { useEffect, useState } from 'react';

//service
import { useGetLimitSettings } from 'services/gameService';

export default function GameLimitSetting({ subgame, limitValue, setLimitValue }) {
  const [list, setList] = useState(null);
  const [{ data, loading, error }, execute] = useGetLimitSettings(subgame.id);

  useEffect(() => {
    execute();
  }, [execute]);

  //get limit template data response
  useEffect(() => {
    if (data) {
      if (data.limitId && data.limitId > 0) {
        setLimitValue(data.limitId);
      } else {
        if (data.data && data.data.length > 0 && data.data[0].limitId > 0) {
          setLimitValue(data.data[0].limitId);
        }
      }
      setList(data.data);
    }
  }, [data, setLimitValue]);

  return (
    <>
      <p className="betlimit-txt">Please select bet limit before entering.</p>

      {loading && <p>loading...</p>}
      {error && <p>something is error occured.</p>}
      {list &&
        list.map((item, i) => {
          return (
            <button
              key={i}
              onClick={() => setLimitValue(item.limitId)}
              className={`betlimit-btn ${item.limitId === limitValue && 'limit-selected'}`}
            >
              {item.min} {' - '} {item.max}
            </button>
          );
        })}
    </>
  );
}
