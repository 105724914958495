import React from 'react';
import { Switch, Route } from 'react-router-dom';

//common
import { ROUTES } from 'common/constants/routing';

//store
//import { useStore } from 'store/StateProvider';

//components
import LayoutRoute from './LayoutRoute';
import { BrandRoutes } from 'component/mobile/app/routes/BrandRoutes';
import PaymentWrapper from 'component/mobile/wrapper/PaymentWrapper';

const { Home, UpdatePrimaryInfo, Login, Logout, Register, ForgotPassword, ResetPassword, Profile, ErrorPage, NotFound, Deposit,
  ProfileEdit, Verify, Messages, Message, Transaction, Withdrawal, Download, Promotion, PromotionDetails, WithdrawalBinding,
  InstagramCallback, Maintenance, WithdrawResult } = BrandRoutes;

const AppRoutes = () => {
  // const [store, dispatch] = useStore();

  // useEffect(() => {
  //   console.log('store', store);
  // }, [store, dispatch]);
  const isDownloadPrivate = window.WEB_CODE === "SW99" ? false : true;
  return (
    <Switch>
      <LayoutRoute exact path={ROUTES.HOME} component={Home} />
      <LayoutRoute path={ROUTES.ERROR} component={ErrorPage} />
      <LayoutRoute path={ROUTES.MAINTENANCE.ROOT} component={Maintenance} />

      <LayoutRoute path={ROUTES.ACCOUNT.LOGIN} component={Login} protectedRoute={true} />
      <LayoutRoute path={ROUTES.ACCOUNT.REGISTER} component={Register} protectedRoute={true} />
      <LayoutRoute path={ROUTES.ACCOUNT.FORGOT_PASSWORD} component={ForgotPassword} protectedRoute={true} />
      <LayoutRoute path={ROUTES.ACCOUNT.LOGOUT} component={Logout} />
      <LayoutRoute path={ROUTES.ACCOUNT.RESET_PASSWORD} component={ResetPassword} protectedRoute={true} />
      <LayoutRoute path={ROUTES.ACCOUNT.VERIFY} component={Verify} />
      <LayoutRoute path={ROUTES.ACCOUNT.THIRD_PARTY_UPDATE_INFO} component={UpdatePrimaryInfo} privateRoute={true} />
      <LayoutRoute path={ROUTES.ACCOUNT.THIRD_PARTY_INSTRAGRAM_CALLBACK} component={InstagramCallback} />

      <LayoutRoute exact path={ROUTES.MESSAGE.ROOT} component={Messages} privateRoute={true} />
      <LayoutRoute exact path={ROUTES.MESSAGE.READ} component={Message} privateRoute={true} />

      <LayoutRoute exact path={ROUTES.USER.PROFILE.ROOT} component={Profile} privateRoute={true} />
      <LayoutRoute exact path={ROUTES.USER.PROFILE.EDIT} component={ProfileEdit} privateRoute={true} />

      <LayoutRoute path={ROUTES.WALLET.DEPOSIT} component={Deposit} privateRoute={true} />

      <LayoutRoute exact path={ROUTES.TRANSACTION.ROOT} component={Transaction} privateRoute={true} />

      <LayoutRoute path={ROUTES.WALLET.WITHDRAWAL} component={Withdrawal} privateRoute={true} />
      <LayoutRoute path={ROUTES.WALLET.WITHDRAWALBINDING} component={WithdrawalBinding} privateRoute={true} />
      <LayoutRoute path={ROUTES.WALLET.WITHDRAWALRESULT} component={WithdrawResult} privateRoute={true} />

      <LayoutRoute exact path={ROUTES.DOWNLOAD.ROOT} component={Download} privateRoute={isDownloadPrivate} />

      <LayoutRoute exact path={ROUTES.PROMOTION.ROOT} component={Promotion} />
      <LayoutRoute exact path={ROUTES.PROMOTION.READ} component={PromotionDetails} />

      <Route path={ROUTES.WALLET.WRAPPER} render={()=> <PaymentWrapper/>} />

      <LayoutRoute exact component={NotFound} />
    </Switch>
  );
};

export default AppRoutes;
