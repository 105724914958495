export const ACTIONS = {
    USER: {
        USER_DETAILS_SET: 'USER_DETAILS_SET',
        USER_LOGOUT: 'USER_LOGOUT',
        USER_BALANCE: 'USER_BALANCE',
    },
    IP_ADDRESS: {
        IP_ADDRESS_SET: 'IP_ADDRESS_SET',
        IP_ADDRESS_CLEAR: 'IP_ADDRESS_CLEAR'
    },
    ERROR: {
        ERROR_SET: 'ERROR_SET',
        ERROR_CLEAR: 'ERROR_CLEAR',
    },
    MESSAGE: {
        MESSAGE_UNREAD_COUNT: 'MESSAGE_UNREAD_COUNT',
        MESSAGE_CLEAR: 'MESSAGE_CLEAR',
    }
};
