const LP99_en = {
    "login": {
        "title": "LOGIN",
        "form_login_label": "Email / Username",
        "form_pw_label": "Password",
        "form_remember_label": "Remember",
        "form_forget_pw_label": "Forget your password?",
        "not_reg_label": "Not Registered?",
        "reg_btn": "REGISTER",
        "info": "* If you didn't see your email verification, try to look in promotion tab or all inbox email."
    },
    "register": {
        "title": "REGISTER",
        "aff_label": "Contact Name",
        "form_phone_label": "Phone",
        "form_email_label": "Email",
        "form_firstname_label": "First Name",
        "form_lastname_label": "Last Name",
        "form_name_label": "Name",
        "form_loginname_label": "Login Name",
        "form_pw_label": "Password",
        "form_cpw_label": "Confirm Password",
        "form_acceptTerms_label": "By clicking this button you confirm that you have read and agree to the Terms and Conditions and Privacy Policy of the company and confirm that you are of legal age.",
        "info": "* If you didn't see your email verification, try to look in promotion tab or all inbox email."
    },
    "forget_pw": {
        "title": "FORGET PASSWORD",
        "form_email_label": "Email",
        "submit_btn": "SUBMIT",
    },
    "deposit": {
        "title": "DEPOSIT",
        "deposit_lower": "Deposit",
        "select_deposit": "Select a deposit method",
        "account_balance": "Account Balance",
        "as_of": "as of",
        "verify": "Verify",
        "instruction": "Instructions (PDF)",
        "min": "Min",
        "max": "Max",
        "bonus_code": "Bonus Code",
        "channel_error": "Error: Channel setting not found",
        "deposit_submit": "Deposit Submitted",
        "deposit_id": "Deposit Reference ID",
    },
}

export default LP99_en;