export const STORAGE = {
    AccessToken: 'Access-Token',
    RememberMe: 'RememberMe',
    ExpiredTime: '_expiredTime',
    ReferralCode: 'AffKey', //referral code
    ReferralDomain: 'AffDomain', //referral domain url
    MemberReferralCode: '',
    PopupBanner: 'PopupBanner',
    Theme: 'theme'
};

export const COOKIE = {
    RefreshToken: 'refreshToken',
};

export const contentType = {
    form: 'application/x-www-form-urlencoded',
    json: 'application/json',
};

export const headerKeys = {
    ContentType: 'Content-Type',
    Authorization: 'Authorization',
};

export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
    HEAD: 'HEAD',
    OPTIONS: 'OPTIONS',
};

export const ThirdParty = {
    FACEBOOK: {
        PROVIDER: 1,
        KEY: window.FACEBOOK_KEY || undefined,
    },
    INSTAGRAM: {
        PROVIDER: 2,
        KEY: window.INSTAGRAM_KEY || undefined,
    },
};

export const SubGameIdList = {
    LiveCasino: 1,
    Slots: 2,
    Sports: 3,
    Table: 4,
    ESports: 5,
    Fishing: 6,
    Animal: 7,
    Poker: 8,
}