import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

//common
import { isUserLoggedIn } from 'common/helpers/localStorage';

//service
import {
  useGetCurrentUser,
  useUserSessionActive,
  useUserPing,
} from 'services/accountService';

//component
import useInterval from 'services/hook/useInterval';
import IdleLogoutTimer from '../common/IdleTimer/IdleLogoutTimer';
import { useStore } from 'store/StateProvider';

export default function UserProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const isLoggedIn = isUserLoggedIn();
  const [store] = useStore();
  const [focus, setFocus] = useState(false);
  const [{ error }, execute] = useGetCurrentUser(true);
  const [{ data, loading }, refetch] = useUserSessionActive(store?.user?.id);
  const [, ping] = useUserPing();

  useEffect(() => {
    if (isLoggedIn) {
      execute();
      window.addEventListener('focus', () => setFocus(true), false);
      window.addEventListener('blur', () => setFocus(false), false);
    }
  }, [isLoggedIn, execute]);

  useInterval(() => {
    if (isLoggedIn && store.user) refetch();
  }, 60000);

  useEffect(() => {
    if (loading) return;
    if (data && data.active) ping();
  }, [loading, data, ping]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
        autoHideDuration: 2500,
      });
    }
  }, [error, enqueueSnackbar]);

  return (
    <>
      {isLoggedIn && focus && <IdleLogoutTimer warningMin={1} timeoutMin={15} />}
      {children}
    </>
  );
}
