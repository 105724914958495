import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RecentGamePlayed from './RecentGamePlayed';
import GameItem from '../common/LaunchGame/GameItem';
import SearchByName from './SearchByName';
import { scroller } from 'react-scroll';

//common
import { isUserLoggedIn } from 'common/helpers/localStorage';
import { sortListAsc } from 'common/helpers/helper';

//service
import { useGetGameList } from 'services/gameService';

const createTab = (name, id) => {
  return <Tab label={name} value={id} />;
};

export default function GameProvider({ showProvider, handleGameFilter, hash, subgameList = [] }) {
  const isLoggedIn = isUserLoggedIn();
  const [{ data }] = useGetGameList();
  const { pathname } = useLocation();
  const showSearchBar = (pathname.indexOf("table") > -1 || pathname.indexOf("poker") > -1) ? false : true;
  const showTabs = (pathname.indexOf("poker") > -1) ? false : true;
  const [gameList, setGameList] = useState([]);

  const filterSubGameList = (gameId) => {
    return subgameList.filter(function (el) {
      return el.gameId === gameId;
    });
  };

  useEffect(() => {
    if (data && data.gameList) {
      let gameData = sortListAsc(data.gameList);
      let resData = [];

      //process subgameList and map to gameList
      gameData.forEach((item) => {
        let r = filterSubGameList(item.id);
        item.subgameList = r;
        if (r.length > 0) {
          resData.push(item);
        }
      });

      setGameList(resData);
    }
  }, [data]);

  useEffect(() => {
    if (gameList && gameList.length > 0 && hash) {
      const slotProviderStr = hash.split('#');
      const slotProvider = parseInt(slotProviderStr[1]);

      scroller.scrollTo('scroll-slot-' + slotProvider, {
        duration: 1000,
        delay: 100,
        smooth: true,
        offset: -15,
      });

      handleGameFilter(null, slotProvider);
    }
  }, [gameList, hash]);

  return (
    <>
      <div className="dt-h-row" id="scroll-slot-all">
        {showTabs && (
          <div className="dt-h-slot-nav gamefilter-tabs">
            {gameList.length > 0 && (
              <Tabs
                id="SUBGAME-ALL"
                value={showProvider}
                onChange={handleGameFilter}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
              >
                {createTab('ALL', 999)}
                {gameList.map((item, i) => {
                  return (
                    <Tab key={i} label={item.name} value={item.id} id={'scroll-slot-' + item.id} />
                  );
                })}
                {isLoggedIn && createTab('RECENTPLAY', 666)}
              </Tabs>
            )}
          </div>
        )}

        {showSearchBar && (
          <div className="dt-h-search">
            <SearchByName />
          </div>
        )}

      </div>

      <div className="dt-h-slot-game-session">
        <ul className="dt-h-slot-game-ind">
          {subgameList.map((item, i) => {
            return (
              (showProvider === 999 || showProvider === item.gameId) && (
                <GameItem item={item} index={i} key={'game-items-' + i} />
              )
            );
          })}

          {isLoggedIn && showProvider === 666 && <RecentGamePlayed />}
        </ul>
      </div>
    </>
  );
}
