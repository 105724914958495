import { BrowserRouter } from 'react-router-dom';
import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';

//store
import { StateProvider } from 'store/StateProvider';
import appReducer from 'store/appReducer';

//routes
import AppRoutes from 'component/mobile/app/routes/AppRoutes';
import DesktopRoutes from 'component/desktop/app/routes/DesktopRoutes';

//service
import { InjectAxiosInterceptors } from 'services/interceptors/InjectAxiosInterceptors';

//components
import UserProvider from 'component/mobile/provider/UserProvider';
import IpAddressProvider from 'component/mobile/provider/IpAddressProvider';
// import './ThemeSetting';

//intl
import { IntlProvider } from 'react-intl';
import flatten from 'flat';

//helper
import { loadTheme } from 'common/helpers/helper';

import { myTheme as LP99theme } from 'common/assets/styles/muitheme.LP99';
import { myTheme as SW99theme } from 'common/assets/styles/muitheme.SW99';
import { myTheme as TEST1theme } from 'common/assets/styles/muitheme.TEST1';

import msg_cn from 'common/lang/LP99_cn.js';
import msg_en from 'common/lang/LP99_en.js';

//i18n
const messages = {
  cn: flatten(msg_cn),
  en: flatten(msg_en),
};
const language = 'en'; // define lang

function getMuiTheme(webcode) {
  return {
    LP99: LP99theme,
    SW99: SW99theme,
    TEST1: TEST1theme,
  }[webcode];
}

const theme = createTheme(getMuiTheme(window.WEB_CODE));

const MobileLayout = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return isMobile ? children : null;
};

const DefaultLayout = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 1025 });
  loadTheme(!isNotMobile);
  return isNotMobile ? children : null;
};

const App = () => {
  const notistackRef = useRef();

  const onClose = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const useStyles = makeStyles((theme) => ({
    success: { backgroundColor: '#222224', color: 'limegreen;' },
    error: { backgroundColor: '#424244', color: '#D03B59' },
    warning: { backgroundColor: '#424244', color: '#D03B59' },
    info: { backgroundColor: '#222224', color: '#ffe4ac' },
  }));

  const classes = useStyles();

  return (
    <BrowserRouter>
      <StateProvider reducer={appReducer} initialState={{}}>
        <InjectAxiosInterceptors />
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <SnackbarProvider
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
            maxSnack={3}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            ref={notistackRef}
            action={(key) => <div onClick={onClose(key)}></div>}
          >
            <UserProvider>
              <IpAddressProvider>
                <ThemeProvider theme={theme}>
                  <IntlProvider locale={language} messages={messages[language]}>
                    <MobileLayout>
                      <AppRoutes />
                    </MobileLayout>
                    <DefaultLayout>
                      <DesktopRoutes />
                    </DefaultLayout>
                  </IntlProvider>
                </ThemeProvider>
              </IpAddressProvider>
            </UserProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </StateProvider>
    </BrowserRouter>
  );
};

export default App;
