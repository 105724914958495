import { API } from 'common/constants/api';
import { Settings } from 'common/constants/common';
import LaunchGameHandler from './LaunchGame';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const DEFAULT_IMG = Settings.SLOT_DEFAULT_IMG;

export default function GameItem({ item, index }) {
  const i = index;

  let isJackpot = item.isJackpot;

  // for open game in promotion
  if (typeof isJackpot === 'number') {
    isJackpot = (isJackpot === 0 ? false : true);
  }

  return (
    <li>
      {
        !item.isMaintenance && isJackpot &&
        <div className="dt-icon-jackpot">
          <img src={API.CDN_REACT + "/jackpot_icon.png"} alt="Jackpot" />
        </div>
      }
      {
        window.WEB_CODE !== "LP99" && item.isMaintenance &&
        <div className="dt-icon-maintenance">
          <img src={API.CDN_REACT + "/game-maintenance.png"} alt="Game Maintenance" />
        </div>
      }
      {
        window.WEB_CODE === "LP99" && item.isMaintenance && item.lobby !== "LiveCasino" &&
        <div className="dt-icon-maintenance">
          <img src={API.CDN_REACT + "/game-maintenance.png"} alt="Game Maintenance" />
        </div>
      }
      {
        window.WEB_CODE === "LP99" && item.isMaintenance && item.lobby === "LiveCasino" &&
        <div className="dt-icon-maintenance">
          <img src={API.CDN_REACT + "/live-game-maintenace.png"} alt="Game Maintenance" />
        </div>
      }

      <LaunchGameHandler subgame={item}>
        <LazyLoadImage
          className="d-slider-img"
          alt={i + '-' + item.name}
          src={item.icon ? API.CDN + item.icon : DEFAULT_IMG}
        />
        {item.gameCode &&
          <span>{item.gameCode.toUpperCase()}</span>
        }
        <p>{item.name}</p>
      </LaunchGameHandler>
    </li>
  )
}