import React, { useEffect, useState } from 'react';

//components
import Loading from 'component/mobile/common/Loading/Loading';
//service
import { useGetRecentGamePlayed } from 'services/gameService';

//common
import GameItem from '../common/LaunchGame/GameItem';

export default function RecentGamePlayed() {
  const [{ data, loading }] = useGetRecentGamePlayed();
  const [recents, setRecents] = useState([]);

  useEffect(() => {
    if (data) {
      setRecents(data);
    }
  }, [data]);

  return (
    <>
      {loading && <Loading loading={loading} />}
      {
        recents.length > 0 && recents.map((item, i) => {
          return (
            <GameItem item={item} index={i} key={'game-items-' + i} />
          );
        })
      }
    </>
  );
}
