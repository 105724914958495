import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setupInterceptors } from './setupInterceptors';
import { useDispatch } from '../../store/StateProvider';

export function InjectAxiosInterceptors() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setupInterceptors(history, dispatch);
  }, [history, dispatch]);

  // not rendering anything
  return null;
}
